import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';

// lazy load all the views

//landing page
const YourSay = React.lazy(() => import('../pages/landing/your_say/YourSay'));

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

const Login2 = React.lazy(() => import('../pages/account2/Login2'));
const Logout2 = React.lazy(() => import('../pages/account2/Logout2'));
const Register2 = React.lazy(() => import('../pages/account2/Register2'));
const Confirm2 = React.lazy(() => import('../pages/account2/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('../pages/account2/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('../pages/account2/LockScreen2'));

// sliders
const SlidersView = React.lazy(() => import('../pages/admin/sliders/slider/SlidersView'));
const EditSlider = React.lazy(() => import('../pages/admin/sliders/slider/EditSlider'));
const AddSlider = React.lazy(() => import('../pages/admin/sliders/slider/AddSlider'));
const SliderZoneView = React.lazy(() => import('../pages/admin/sliders/zone/SliderZoneView'));
const AddSliderZone = React.lazy(() => import('../pages/admin/sliders/zone/AddSliderZone'));
const EditSliderZone = React.lazy(() => import('../pages/admin/sliders/zone/EditSliderZone'));

// steps
const StepView = React.lazy(() => import('../pages/admin/step/StepView'));
const AddStep = React.lazy(() => import('../pages/admin/step/AddStep'));
const EditStep = React.lazy(() => import('../pages/admin/step/EditStep'));

// administrators
const AdministratorView = React.lazy(() => import('../pages/admin/administrator/admin/AdministratorView'));
const AddAdministrator = React.lazy(() => import('../pages/admin/administrator/admin/AddAdministrator'));
const EditAdministrator = React.lazy(() => import('../pages/admin/administrator/admin/EditAdministrator'));
const RoleView = React.lazy(() => import('../pages/admin/administrator/role/RoleView'));
const AddAdminRole = React.lazy(() => import('../pages/admin/administrator/role/AddAdminRole'));
const EditAdminRole = React.lazy(() => import('../pages/admin/administrator/role/EditAdminRole'));

// catgories
const CategoryView = React.lazy(() => import('../pages/admin/category/CategoryView'));
const AddCategory = React.lazy(() => import('../pages/admin/category/AddCategory'));
const EditCategory = React.lazy(() => import('../pages/admin/category/EditCategory'));

const UserView = React.lazy(() => import('../pages/admin/user/UsersView'));
const IdeaView = React.lazy(() => import('../pages/admin/idea/IdeaView'));
const StatisticDashboardPage = React.lazy(() => import('../pages/admin/statistic'));

// dashboard
const AnalyticsDashboard = React.lazy(() => import('../pages/dashboard/Analytics'));
const EcommerceDashboard = React.lazy(() => import('../pages/dashboard/Ecommerce'));
const ProjectDashboard = React.lazy(() => import('../pages/dashboard/Project'));
const EWalletDashboard = React.lazy(() => import('../pages/dashboard/E-Wallet'));

// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const Projects = React.lazy(() => import('../pages/apps/Projects'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Projects/Detail'));
const ProjectGannt = React.lazy(() => import('../pages/apps/Projects/Gantt'));
const ProjectForm = React.lazy(() => import('../pages/apps/Projects/ProjectForm'));

// - chat
const ChatApp = React.lazy(() => import('../pages/apps/Chat'));

// -crm
const CRMDashboard = React.lazy(() => import('../pages/apps/CRM/Dashboard'));
const CRMProjects = React.lazy(() => import('../pages/apps/CRM/Projects'));
const CRMManagement = React.lazy(() => import('../pages/apps/CRM/Management'));
const CRMClients = React.lazy(() => import('../pages/apps/CRM/Clients'));
const CRMOrderList = React.lazy(() => import('../pages/apps/CRM/OrderList'));

// - ecommece pages
const EcommerceProducts = React.lazy(() => import('../pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('../pages/apps/Ecommerce/ProductDetails'));
const Orders = React.lazy(() => import('../pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('../pages/apps/Ecommerce/OrderDetails'));
const Customers = React.lazy(() => import('../pages/apps/Ecommerce/Customers'));
const Cart = React.lazy(() => import('../pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('../pages/apps/Ecommerce/Checkout'));
const Sellers = React.lazy(() => import('../pages/apps/Ecommerce/Sellers'));

// - email
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));

// - social
const SocialFeed = React.lazy(() => import('../pages/apps/SocialFeed'));

// - tasks
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
const TaskDetails = React.lazy(() => import('../pages/apps/Tasks/Details'));
const Kanban = React.lazy(() => import('../pages/apps/Tasks/Board'));
// - file
const FileManager = React.lazy(() => import('../pages/apps/FileManager'));

// pages
const Profile = React.lazy(() => import('../pages/profile'));
const Profile2 = React.lazy(() => import('../pages/profile2'));
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// - other
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const Starter = React.lazy(() => import('../pages/other/Starter'));
const PreLoader = React.lazy(() => import('../pages/other/PreLoader'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));

const Landing = React.lazy(() => import('../pages/landing'));

// uikit
const Accordions = React.lazy(() => import('../pages/uikit/Accordions'));
const Alerts = React.lazy(() => import('../pages/uikit/Alerts'));
const Avatars = React.lazy(() => import('../pages/uikit/Avatars'));
const Badges = React.lazy(() => import('../pages/uikit/Badges'));
const Breadcrumbs = React.lazy(() => import('../pages/uikit/Breadcrumb'));
const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
const Cards = React.lazy(() => import('../pages/uikit/Cards'));
const Carousels = React.lazy(() => import('../pages/uikit/Carousel'));
const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
const EmbedVideo = React.lazy(() => import('../pages/uikit/EmbedVideo'));
const Grid = React.lazy(() => import('../pages/uikit/Grid'));
const ListGroups = React.lazy(() => import('../pages/uikit/ListGroups'));
const Modals = React.lazy(() => import('../pages/uikit/Modals'));
const Notifications = React.lazy(() => import('../pages/uikit/Notifications'));
const Offcanvases = React.lazy(() => import('../pages/uikit/Offcanvas'));
const Paginations = React.lazy(() => import('../pages/uikit/Paginations'));
const Popovers = React.lazy(() => import('../pages/uikit/Popovers'));
const Progress = React.lazy(() => import('../pages/uikit/Progress'));
const Ribbons = React.lazy(() => import('../pages/uikit/Ribbons'));
const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
const Tabs = React.lazy(() => import('../pages/uikit/Tabs'));
const Tooltips = React.lazy(() => import('../pages/uikit/Tooltips'));
const Typography = React.lazy(() => import('../pages/uikit/Typography'));
const DragDrop = React.lazy(() => import('../pages/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSliders'));
const Ratings = React.lazy(() => import('../pages/uikit/Ratings'));

// icons
const Dripicons = React.lazy(() => import('../pages/icons/Dripicons'));
const MDIIcons = React.lazy(() => import('../pages/icons/MDIIcons'));
const Unicons = React.lazy(() => import('../pages/icons/Unicons'));

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editors = React.lazy(() => import('../pages/forms/Editors'));

// charts
const ApexChart = React.lazy(() => import('../pages/charts/Apex'));
const BriteChart = React.lazy(() => import('../pages/charts/Brite'));
const ChartJs = React.lazy(() => import('../pages/charts/ChartJs'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

// widgets
const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));

// maps
const GoogleMaps = React.lazy(() => import('../pages/maps/GoogleMaps'));

const loading = () => <div className=""></div>;

const LoadComponent = ({ component: Component }) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'login2', element: <LoadComponent component={Login2} /> },
                        { path: 'register2', element: <LoadComponent component={Register2} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                        { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: '/home',
                    element: <LoadComponent component={Landing} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={['Admin', 'Superadmin']} component={Layout} />,
            children: [
                {
                    path: 'admin',
                    children: [
                        {
                            path: 'slider',
                            children: [
                                {
                                    path: '', //Liste slider | edit | delete | send email
                                    element: <LoadComponent component={SlidersView} />,
                                },
                                {
                                    path: 'edit/:id',//Categorie de la slider
                                    element: <LoadComponent component={EditSlider} />,
                                },
                                {
                                    path: 'zone',//Categorie de la slider
                                    element: <LoadComponent component={SliderZoneView} />,
                                },
                                {
                                    path: 'add', //Add an slider
                                    element: <LoadComponent component={AddSlider} />,
                                },
                                {
                                    path: 'zone/add', //Add an slider
                                    element: <LoadComponent component={AddSliderZone} />,
                                },
                                {
                                    path: 'zone/edit/:id', //Edit an slider zone
                                    element: <LoadComponent component={EditSliderZone} />,
                                },
                            ],
                        },
                        {
                            path: 'user',
                            children: [
                                {
                                    path: '', //liste user // search and filter
                                    element: <LoadComponent component={UserView} />,
                                },
                                {
                                    path: 'send-mail', // Send email on select users
                                    element: <LoadComponent component={Inbox} />,
                                },
                            ],
                        },
                        {
                            path: 'administrator',
                            children: [
                                {
                                    path: '', //liste admin // btn filter => filtre | delete | edit => popup - role | btn add admin => user liste - select box => Add admin 
                                    element: <LoadComponent component={AdministratorView} />,
                                },
                                {
                                    path: 'add', //liste admin // btn filter => filtre | delete | edit => popup - role | btn add admin => user liste - select box => Add admin 
                                    element: <LoadComponent component={AddAdministrator} />,
                                },
                                {
                                    path: 'edit/:id', //liste admin // btn filter => filtre | delete | edit => popup - role | btn add admin => user liste - select box => Add admin 
                                    element: <LoadComponent component={EditAdministrator} />,
                                },
                                {
                                    path: 'role', // Admin roles => Superadmin, admin, supervisor
                                    element: <LoadComponent component={RoleView} />,
                                },
                                {
                                    path: 'role/add', // Admin roles => Superadmin, admin, supervisor
                                    element: <LoadComponent component={AddAdminRole} />,
                                },
                                {
                                    path: 'role/edit/:id', // Admin roles => Superadmin, admin, supervisor
                                    element: <LoadComponent component={EditAdminRole} />,
                                },
                            ],
                        },
                        {
                            path: 'jury',
                            children: [
                                {
                                    path: '', //liste of juries // btn filter => filtre | delete | edit => popup - role | btn add jury => user liste - select box => Add jury 
                                    element: <LoadComponent component={Customers} />,
                                }
                            ],
                        },
                        {
                            path: 'notation-jury',
                            children: [
                                {
                                    path: '', //liste idea by dropdown of jury capacity / filter component / add an idea note in popup / edit an idea note on popup
                                    element: <LoadComponent component={Customers} />,
                                }
                            ],
                        },
                        {
                            path: 'idea',
                            children: [
                                {
                                    path: '', //liste all idea / filter component /   view
                                    element: <LoadComponent component={IdeaView} />,
                                },
                                {
                                    path: 'view/:id', //view steam member  / view jury vote /  view jury resume note / send message to team members
                                    element: <LoadComponent component={Customers} />,
                                }
                            ],
                        },
                        {
                            path: 'category',
                            children: [
                                {
                                    path: '', //liste categories / filter component / btn add => popup /   edit |   delete
                                    element: <LoadComponent component={CategoryView} />,
                                },
                                {
                                    path: 'add', //liste categories / filter component / btn add => popup /   edit |   delete
                                    element: <LoadComponent component={AddCategory} />,
                                },
                                {
                                    path: 'edit/:id', //liste categories / filter component / btn add => popup /   edit |   delete
                                    element: <LoadComponent component={EditCategory} />,
                                }
                            ],
                        },
                        {
                            path: 'steps',
                            children: [
                                {
                                    path: '', //liste steps / btn add =>  /   edit |   delete
                                    element: <LoadComponent component={StepView} />,
                                },
                                {
                                    path: 'add', //liste steps / btn add =>  /   edit |   delete
                                    element: <LoadComponent component={AddStep} />,
                                },
                                {
                                    path: 'edit/:id', //liste steps / btn add =>  /   edit |   delete
                                    element: <LoadComponent component={EditStep} />,
                                }
                            ],
                        },
                        {
                            path: 'newsletter',
                            children: [
                                {
                                    path: 'subscriber', //list of newsletter subscriber / filter component /   delete
                                    element: <LoadComponent component={Customers} />,
                                },
                                {
                                    path: 'list', //list of newsletter / filter component / btn add => page / edit => page | delete
                                    element: <LoadComponent component={Customers} />,
                                    children: [
                                        {
                                            path: 'add', //add newsletter html format
                                            element: <LoadComponent component={Customers} />,
                                        },
                                        {
                                            path: 'edit/:id', //add newsletter html format
                                            element: <LoadComponent component={Customers} />,
                                        },
                                    ],
                                },
                                {
                                    path: 'send-newsletter', //Sends news at one or many subscriber
                                    element: <LoadComponent component={Customers} />,
                                },
                            ],
                        },
                        {
                            path: 'tracking-tool',
                            children: [
                                {
                                    path: '',
                                    element: <LoadComponent component={ProjectDashboard} />,
                                },
                            ],
                        },
                        {
                            path: 'media',
                            children: [
                                {
                                    path: 'picture',
                                    element: <LoadComponent component={ProjectDashboard} />,
                                },
                                {
                                    path: 'movie',
                                    element: <LoadComponent component={ProjectDashboard} />,
                                },
                                {
                                    path: 'news',
                                    element: <LoadComponent component={ProjectDashboard} />,
                                },
                            ],
                        },
                        {
                            path: 'rapports',
                            element: <LoadComponent component={ProjectDashboard} />,
                        },
                        {
                            path: 'statistic',
                            element: <LoadComponent component={StatisticDashboardPage} />,
                        },
                    ],
                },
            ]
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={['Admin', 'Superadmin']} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'analytics',
                            element: <LoadComponent component={AnalyticsDashboard} />,
                        },
                        {
                            path: 'ecommerce',
                            element: <LoadComponent component={EcommerceDashboard} />,
                        },
                        {
                            path: 'project',
                            element: <LoadComponent component={ProjectDashboard} />,
                        },
                        {
                            path: 'e-wallet',
                            element: <LoadComponent component={EWalletDashboard} />,
                        },
                    ],
                },
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'calendar',
                            element: <LoadComponent component={CalendarApp} />,
                        },
                        {
                            path: 'chat',
                            element: <LoadComponent component={ChatApp} />,
                        },
                        {
                            path: 'crm',
                            children: [
                                {
                                    path: 'dashboard',
                                    element: <LoadComponent component={CRMDashboard} />,
                                },
                                {
                                    path: 'projects',
                                    element: <LoadComponent component={CRMProjects} />,
                                },
                                {
                                    path: 'management',
                                    element: <LoadComponent component={CRMManagement} />,
                                },
                                {
                                    path: 'clients',
                                    element: <LoadComponent component={CRMClients} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={CRMOrderList} />,
                                },
                            ],
                        },
                        {
                            path: 'ecommerce',
                            children: [
                                {
                                    path: 'products',
                                    element: <LoadComponent component={EcommerceProducts} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProductDetails} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={Orders} />,
                                },
                                {
                                    path: 'order/details',
                                    element: <LoadComponent component={OrderDetails} />,
                                },
                                {
                                    path: 'customers',
                                    element: <LoadComponent component={Customers} />,
                                },
                                {
                                    path: 'shopping-cart',
                                    element: <LoadComponent component={Cart} />,
                                },
                                {
                                    path: 'checkout',
                                    element: <LoadComponent component={Checkout} />,
                                },
                                {
                                    path: 'sellers',
                                    element: <LoadComponent component={Sellers} />,
                                },
                            ],
                        },
                        {
                            path: 'email',
                            children: [
                                {
                                    path: 'inbox',
                                    element: <LoadComponent component={Inbox} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={EmailDetail} />,
                                },
                            ],
                        },
                        {
                            path: 'tasks',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={TaskList} />,
                                },
                                {
                                    path: 'kanban',
                                    element: <LoadComponent component={Kanban} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={TaskDetails} />,
                                },
                            ],
                        },

                        {
                            path: 'projects',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={Projects} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProjectDetail} />,
                                },
                                {
                                    path: 'gantt',
                                    element: <LoadComponent component={ProjectGannt} />,
                                },
                                {
                                    path: 'new',
                                    element: <LoadComponent component={ProjectForm} />,
                                },
                            ],
                        },
                        {
                            path: 'social',
                            element: <LoadComponent component={SocialFeed} />,
                        },
                        {
                            path: 'file',
                            element: <LoadComponent component={FileManager} />,
                        },
                    ],
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'starter',
                            element: <LoadComponent component={Starter} />,
                        },
                        {
                            path: 'profile',
                            element: <LoadComponent component={Profile} />,
                        },
                        {
                            path: 'profile2',
                            element: <LoadComponent component={Profile2} />,
                        },
                        {
                            path: 'pricing',
                            element: <LoadComponent component={Pricing} />,
                        },
                        {
                            path: 'error-404-alt',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                        {
                            path: 'timeline',
                            element: <LoadComponent component={Timeline} />,
                        },
                        {
                            path: 'invoice',
                            element: <LoadComponent component={Invoice} />,
                        },
                        {
                            path: 'faq',
                            element: <LoadComponent component={FAQ} />,
                        },
                        {
                            path: 'preloader',
                            element: <LoadComponent component={PreLoader} />,
                        },
                    ],
                },
                {
                    path: 'ui',
                    children: [
                        {
                            path: 'base-ui',
                            children: [
                                {
                                    path: 'accordions',
                                    element: <LoadComponent component={Accordions} />,
                                },
                                {
                                    path: 'alerts',
                                    element: <LoadComponent component={Alerts} />,
                                },
                                {
                                    path: 'avatars',
                                    element: <LoadComponent component={Avatars} />,
                                },
                                {
                                    path: 'badges',
                                    element: <LoadComponent component={Badges} />,
                                },
                                {
                                    path: 'breadcrumb',
                                    element: <LoadComponent component={Breadcrumbs} />,
                                },
                                {
                                    path: 'buttons',
                                    element: <LoadComponent component={Buttons} />,
                                },
                                {
                                    path: 'cards',
                                    element: <LoadComponent component={Cards} />,
                                },
                                {
                                    path: 'carousel',
                                    element: <LoadComponent component={Carousels} />,
                                },
                                {
                                    path: 'dropdowns',
                                    element: <LoadComponent component={Dropdowns} />,
                                },
                                {
                                    path: 'embedvideo',
                                    element: <LoadComponent component={EmbedVideo} />,
                                },
                                {
                                    path: 'grid',
                                    element: <LoadComponent component={Grid} />,
                                },
                                {
                                    path: 'listgroups',
                                    element: <LoadComponent component={ListGroups} />,
                                },
                                {
                                    path: 'modals',
                                    element: <LoadComponent component={Modals} />,
                                },
                                {
                                    path: 'notifications',
                                    element: <LoadComponent component={Notifications} />,
                                },
                                {
                                    path: 'offcanvas',
                                    element: <LoadComponent component={Offcanvases} />,
                                },
                                {
                                    path: 'paginations',
                                    element: <LoadComponent component={Paginations} />,
                                },
                                // {
                                //     path: 'placeholders',
                                //     element: <LoadComponent component={Placeholders} />,
                                // },
                                {
                                    path: 'popovers',
                                    element: <LoadComponent component={Popovers} />,
                                },
                                {
                                    path: 'progress',
                                    element: <LoadComponent component={Progress} />,
                                },
                                {
                                    path: 'ribbons',
                                    element: <LoadComponent component={Ribbons} />,
                                },
                                {
                                    path: 'spinners',
                                    element: <LoadComponent component={Spinners} />,
                                },
                                {
                                    path: 'tabs',
                                    element: <LoadComponent component={Tabs} />,
                                },
                                {
                                    path: 'tooltips',
                                    element: <LoadComponent component={Tooltips} />,
                                },
                                {
                                    path: 'typography',
                                    element: <LoadComponent component={Typography} />,
                                },
                            ],
                        },
                        {
                            path: 'widgets',
                            element: <LoadComponent component={Widgets} />,
                        },
                        {
                            path: 'extended',
                            children: [
                                {
                                    path: 'dragdrop',
                                    element: <LoadComponent component={DragDrop} />,
                                },
                                {
                                    path: 'rangesliders',
                                    element: <LoadComponent component={RangeSliders} />,
                                },
                                {
                                    path: 'ratings',
                                    element: <LoadComponent component={Ratings} />,
                                },
                            ],
                        },
                        {
                            path: 'icons',
                            children: [
                                {
                                    path: 'unicons',
                                    element: <LoadComponent component={Unicons} />,
                                },
                                {
                                    path: 'mdi',
                                    element: <LoadComponent component={MDIIcons} />,
                                },
                                {
                                    path: 'dripicons',
                                    element: <LoadComponent component={Dripicons} />,
                                },
                            ],
                        },
                        {
                            path: 'forms',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicForms} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={FormAdvanced} />,
                                },
                                {
                                    path: 'validation',
                                    element: <LoadComponent component={FormValidation} />,
                                },
                                {
                                    path: 'wizard',
                                    element: <LoadComponent component={FormWizard} />,
                                },
                                {
                                    path: 'upload',
                                    element: <LoadComponent component={FileUpload} />,
                                },
                                {
                                    path: 'editors',
                                    element: <LoadComponent component={Editors} />,
                                },
                            ],
                        },
                        {
                            path: 'tables',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicTables} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={AdvancedTables} />,
                                },
                            ],
                        },
                        {
                            path: 'charts',
                            children: [
                                {
                                    path: 'apex',
                                    element: <LoadComponent component={ApexChart} />,
                                },
                                {
                                    path: 'brite',
                                    element: <LoadComponent component={BriteChart} />,
                                },
                                {
                                    path: 'chartjs',
                                    element: <LoadComponent component={ChartJs} />,
                                },
                            ],
                        },
                        {
                            path: 'maps',
                            children: [
                                {
                                    path: 'googlemaps',
                                    element: <LoadComponent component={GoogleMaps} />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
