// @flow
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

// code splitting and lazy loading
const Header = React.lazy(() => import('../../pages/landing/Header'));
const Navbar = React.lazy(() => import('./Navbar'));
const Footer = React.lazy(() => import('../../pages/landing/component/Footer'));

const loading = () => <div className=""></div>;

const DefaultLayout = () => {

    return (
        <>
            <Suspense fallback={loading()}>
            </Suspense>
            <Suspense fallback={loading()}>
            </Suspense>
            <Suspense fallback={loading()}>
                <Outlet />
            </Suspense>
            <Suspense fallback={loading()}>
            </Suspense>
        </>

    );
};
export default DefaultLayout;
